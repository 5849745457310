.rankingRow {
  font-family: "Impact", Charcoal, sans-serif;
  font-weight: 600;
  font-size: 12vmin;
  /* position: absolute;
      margin-left: -1rem; */
  overflow: hidden;
  color: #3d4f64;
}

@media screen and (max-width: 576px) {
    .rankingRow {
      font-size: 10vmin;
    }
  }