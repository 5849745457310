.pink {
  color: #c80e5e;
}

.bg-pink {
  background-color: #c80e5e;
}

.light-pink {
  color: #f6d9e7;
}

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: #c80e5e;
  top: 0;
  bottom: 0;
  left: 100px;
  margin-left: -3px;
}

/* Container around content */
.phase {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 100%;
  left: 0;
}

/* The circles on the timeline */
.phase::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: 0px;
  background-color: white;
  border: 4px solid #ff9f55;
  top: 50%;
  border-radius: 50%;
  z-index: 1;
  left: 87px;
}

/* The actual content */
.phase-wrapper {
  display: flex;
  position: relative;
  border-radius: 6px;
  min-height: 130px;
}

.phase-side {
  width: 120px;
}

.text-vertical {
  transform: rotate(-90deg);
}

.phase-number {
  position: absolute;
  left: 5px;
  top: 50%;
}

.phase-date {
  position: absolute;
  left: 35px;
  top: 50%;
}



/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 35px;
  }

  /* Full-width containers */
  .phase {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  /* Make sure all circles are at the same spot */
  .phase::after {
    left: 22px;
  }

  .phase-side {
    width: 80px;
  }

  .phase-number {
    position: absolute;
    left: -16px;
    top: 50%;
  }

  .phase-date {
    position: absolute;
    left: 5px;
    top: 50%;
  }
}
