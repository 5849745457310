.calendar-back {
  background-color: #fff9f3;
}

.hover-shadow {
  transition: box-shadow .3s;
}

.hover-shadow:hover {
  box-shadow: 2px 0px 12px 2px rgba(15, 15, 15, 0.2);
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}

.adtvent-frame {
  position: absolute;
  width: 80%;
  top: 20%;
  left: 10%;
}

.bonus-row {
  position: absolute;
  width: 20%;
  top: 10%;
  left: 40%;
}

.adtventScoreRow {
  font-family: "Impact", Charcoal, sans-serif;
  font-weight: 600;
  font-size: 12vmin;
  /* position: absolute;
    margin-left: -1rem; */
  overflow: hidden;
  color: #3d4f64;
}

.chistmas-background {
  background: url(../../images/adtvent/ChristmasBackground.svg);
}

.csstooltip {
  position: relative;
  display: inline-block;
  width: 100%;
  /* border-bottom: 1px dotted black; */
}

.csstooltip .csstooltiptext-left {
  visibility: hidden;
  /* width: 80vw; */
  /* max-height: 100%; */
  right: 1.5rem;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 100;
}

.csstooltip .csstooltiptext-left::after {
  content: "";
  position: absolute;
  top: 0.5rem;
  left: 100%;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}

.csstooltip:hover {
  color: #e8b923
}

.csstooltip:hover .csstooltiptext-left {
  visibility: visible;
}

@media screen and (max-width: 576px) {
  .adtventScoreRow {
    font-size: 10vmin;
  }
}