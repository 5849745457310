.App {
  text-align: center;
  background: url(./images/beerBackground.svg);
}

.bg-custom-blue {
  background-color: #3d4f64;
}

.text-custom-blue {
  color: #3d4f64;
}

.bg-custom-gold {
  background-color: #e8b923;
}

.bg-custom-gold-light {
  background-color: #f6e5ad;
}

.border-custom-gold {
  border-color: #e8b923;
  border-width: 2px;
}

.text-custom-gold {
  color: #e8b923;
}

.xscroll {
  overflow-x: scroll;
}

.modal-dialog {
  width: 70%;
  margin: auto;
}

.modal-content {
  background-color: rgba(0, 0, 0, 0.0001);
  border: none;
}

.LogRes {
  z-index: 99;
}

.loginToggler {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90%;
  background-color: #3d4f64; /*  0275d8 */
  width: 80%;
  position: absolute;
  right: -20%;
  top: 5%;
  border-radius: 6px;
  z-index: -1;
  transition: all 400ms ease-in-out;
  cursor: pointer;
  box-shadow: 0px 0px 12px 2px rgba(15, 15, 15, alpha);
}

.loginTogglerRight {
  right: -15%;
  align-items: flex-end;
}

.loginTogglerRight:hover {
  right: -20%
}

.loginTogglerLeft {
  right: 35%;
  align-items: flex-start;
}

.loginTogglerLeft:hover {
  right: 40%
}

.border-dashed-top {
  border-top: #e8b923 dotted 1px;
}

.border-dashed-notop {
  border: #e8b923 dotted 2px;
  border-top: none;
  border-radius: 0px 0px 15px 15px;
}

.pos-abs {
  position: absolute;
}

.flip-card {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: all 0.5s ease-in-out;
}

.flip-card-front {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
}

.tierCaption {
  font-family: "Impact", Charcoal, sans-serif; font-weight: 600;
  position: absolute;
  font-size: 25vmin;
  margin-left: -1rem;
  overflow: hidden;
  color: #3d4f64 ;
}

.recordsScroll {
  overflow-y: scroll;
  height: 75px;
}

.tour-de-biere-profiel {
  width: 600px;
}

.indicator-blue {
  border-bottom: #3d4f64 solid 4px;
}

.indicator-gold {
  border-bottom: #e8b923 solid 4px;
}
